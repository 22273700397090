import React from "react";

export default function Layout({ children }) {
	return (
		<div>
			<div>this is layout</div>
			{children}
		</div>
	);
}
